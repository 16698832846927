import React from "react";
import styles from "./Character.module.scss";

function Character({ src, alt }) {
  return (
    <div className={styles.container}>
      <img src={src} alt={alt} className={styles.character} />
    </div>
  );
}

export default Character;
