import { getScores, submitScore } from "api/leaderboard";
import React, { useEffect, useState } from "react";
import CharacterSelect from "./CharacterSelect";
import Game from "./Game";
import LeaderBoard from "./Leaderboard";
import styles from "./Snake.module.scss";

function Snake() {
  const url = process.env.REACT_APP_API_URL;
  const uuid = process.env.REACT_APP_API_UUID;

  const [character, setCharacter] = useState();
  const [leaderBoard, setLeaderBoard] = useState(false);
  const [scores, setScores] = useState([]);
  const [loadingScores, setLoadingScores] = useState(true);

  useEffect(() => {
    getScores(url, uuid).then((data) => {
      setScores(data);
      setLoadingScores(false);
    });
  }, [uuid, url]);

  const handleSubmitScore = async (name, score) => {
    return submitScore(url, uuid, name, score)
      .then((r) => {
        if (r.errors) {
          return Promise.reject();
        }
        return getScores(url, uuid).then((data) => {
          setScores(data);
          return Promise.resolve(data);
        });
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };

  return (
    <>
      <div className={styles.overlay}></div>
      <div className={styles.container}>
        {leaderBoard ? (
          <div className={styles.leaderBoardFullscreen}>
            {loadingScores ? (
              <div>Loading scores...</div>
            ) : (
              <LeaderBoard
                closeLeaderboard={() => {
                  setLeaderBoard(false);
                }}
                scores={scores}
              ></LeaderBoard>
            )}
          </div>
        ) : (
          <>
            {character ? (
              <Game
                character={character}
                exitGame={() => setCharacter(null)}
                scores={scores}
                submitScore={handleSubmitScore}
              />
            ) : (
              <CharacterSelect
                setCharacter={setCharacter}
                showLeaderboard={() => {
                  setLeaderBoard(true);
                }}
              />
            )}
          </>
        )}
      </div>
    </>
  );
}

export default Snake;
