export async function submitScore(url, uuid, name, score) {
  const response = await fetch(`${url}/api/scores/` + uuid, {
    method: "POST",
    body: JSON.stringify({
      name: name,
      score: btoa(uuid + "|" + score.current),
    }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((json) => json)
    .catch((err) => {
      return false;
    });

  return await response.json();
}

export async function getScores(url, uuid) {
  const response = await fetch(`${url}/api/scores/` + uuid);

  return await response.json();
}
