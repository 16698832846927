import React from "react";
import Character from "./characters/Character";
import styles from "./CharacterSelect.module.scss";

function importCharacters(r) {
  let images = {};
  r.keys().map((item, index) => {
    return (images[item.replace("./", "")] = r(item));
  });
  return images;
}

const characters = importCharacters(
  require.context(
    `./characters/${process.env.REACT_APP_CHARACTER_DIR}`,
    false,
    /\.(png|jpe?g|svg)$/
  )
);

function CharacterSelect({ setCharacter, showLeaderboard }) {
  return (
    <div className={styles.container}>
      <div className={styles.text}>Select your character...</div>
      <div className={styles.characters}>
        {Object.keys(characters).map((x) => (
          <div
            key={x}
            className={styles.character}
            onClick={() => setCharacter(characters[x])}
          >
            <Character src={characters[x].default} alt="Loading..." />
          </div>
        ))}
      </div>
      <button className={styles.button} onClick={showLeaderboard}>
        High Scores
      </button>
    </div>
  );
}

export default CharacterSelect;
