import React from "react";
import styles from "./Leaderboard.module.scss";

function LeaderBoard({ closeLeaderboard, scores }) {
  return (
    <div className={styles.leaderboard}>
      <h1>High Scores</h1>

      {scores.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Score</th>
            </tr>
          </thead>
          <tbody>
            {scores.map((score, index) => (
              <tr key={index}>
                <td>{score.name}</td>
                <td>{score.score}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No high scores recorded yet!</p>
      )}

      <button onClick={closeLeaderboard}>Exit</button>
    </div>
  );
}

export default LeaderBoard;
