import React, { useEffect, useRef, useState } from "react";
import Character from "./characters/Character";
import Leaderboard from "./Leaderboard";
import styles from "./Game.module.scss";

const rows = 10;
const cols = 10;
const tickTime = 175;

const getRandomGridCoords = () => {
  return {
    row: Math.floor(Math.random() * rows),
    col: Math.floor(Math.random() * cols),
  };
};

const getCenterOfGrid = () => {
  return {
    row: Math.floor((rows - 1) / 2),
    col: Math.floor((cols - 1) / 2),
  };
};

const getFoodItem = () => {
  const foods = ["🍕", "🥪", "🥯", "🍟"];
  return foods[Math.floor(Math.random() * foods.length)];
};

const generateGrid = (food, snake) => {
  const grid = [];

  for (let row = 0; row < rows; row++) {
    for (let col = 0; col < cols; col++) {
      const isFood = food ? food.row === row && food.col === col : false;
      const isHead = snake
        ? snake.head.row === row && snake.head.col === col
        : false;
      const isTail = snake
        ? snake.tail.filter((x) => x.row === row && x.col === col).length
        : false;

      grid.push({ row, col, isFood, isHead, isTail });
    }
  }

  return grid;
};

function Game({ character, exitGame, scores, submitScore }) {
  const ticker = useRef();
  const score = useRef(0);
  const direction = useRef();
  const lastMoveDirection = useRef();
  const food = useRef();
  const snake = useRef();
  const foodItem = useRef();
  const [starting, setStarting] = useState(false);

  const [grid, setGrid] = useState(generateGrid());
  const gridRef = useRef(grid);

  const [started, setStarted] = useState(false);
  const [gameOver, setGameOver] = useState(false);

  const [scoreSubmitMessage, setScoreSubmitMessage] = useState("");
  const [scoreSubmitError, setScoreSubmitError] = useState(false);

  const [name, setName] = useState("");

  useEffect(() => {
    window.addEventListener("keydown", (event) => {
      switch (event.keyCode) {
        case 87: // w
          if (lastMoveDirection.current !== "down") {
            direction.current = "up";
          }
          break;
        case 68: // d
          if (lastMoveDirection.current !== "left") {
            direction.current = "right";
          }
          break;
        case 83: // s
          if (lastMoveDirection.current !== "up") {
            direction.current = "down";
          }
          break;
        case 65: // a
          if (lastMoveDirection.current !== "right") {
            direction.current = "left";
          }
          break;
        default:
        // do nothing
      }
    });
  }, []);

  const handleUp = () => {
    if (lastMoveDirection.current !== "down") {
      direction.current = "up";
    }
  };

  const handleRight = () => {
    if (lastMoveDirection.current !== "left") {
      direction.current = "right";
    }
  };

  const handleDown = () => {
    if (lastMoveDirection.current !== "up") {
      direction.current = "down";
    }
  };

  const handleLeft = () => {
    if (lastMoveDirection.current !== "right") {
      direction.current = "left";
    }
  };
  const tickGame = () => {
    const newSnake = snake.current;

    // move head
    switch (direction.current) {
      case "up":
        newSnake.head.row--;
        lastMoveDirection.current = direction.current;
        break;
      case "right":
        newSnake.head.col++;
        lastMoveDirection.current = direction.current;
        break;
      case "down":
        newSnake.head.row++;
        lastMoveDirection.current = direction.current;
        break;
      case "left":
        newSnake.head.col--;
        lastMoveDirection.current = direction.current;
        break;
      default:
      // do nothing
    }

    // out of bounds
    if (
      newSnake.head.row >= rows ||
      newSnake.head.row < 0 ||
      newSnake.head.col >= cols ||
      newSnake.head.col < 0
    ) {
      clearInterval(ticker.current);
      setGameOver(true);
      return;
    }

    // overlapping snake
    if (
      snake.current.tail.filter(
        (x) => x.row === newSnake.head.row && x.col === newSnake.head.col
      ).length
    ) {
      clearInterval(ticker.current);
      setGameOver(true);

      // render actual overlap
      const newGrid = generateGrid(food.current, newSnake);
      setGrid(newGrid);
      gridRef.current = newGrid;
      return;
    }

    // move tail
    newSnake.tail.unshift({
      row: snake.current.head.row,
      col: snake.current.head.col,
    });
    newSnake.tail.pop();

    // eats found
    if (
      newSnake.head.row === food.current.row &&
      newSnake.head.col === food.current.col
    ) {
      newSnake.tail.push({ row: food.current.row, col: food.current.col });
      food.current = getRandomGridCoords();
      score.current += 500;
      foodItem.current = getFoodItem();
    }

    score.current += 1;

    const newGrid = generateGrid(food.current, newSnake);
    setGrid(newGrid);
    gridRef.current = newGrid;
  };

  const handleStartGame = () => {
    setStarting(true);
    console.log("starting");
    setTimeout(function () {
      setName("");
      setScoreSubmitMessage("");
      setStarted(true);
      score.current = 0;
      direction.current = "right";
      lastMoveDirection.current = "right";
      food.current = getRandomGridCoords();
      foodItem.current = getFoodItem();
      snake.current = { head: getCenterOfGrid(), tail: [getCenterOfGrid()] };
      setGrid(generateGrid(food.current, snake.current));
      setGameOver(false);
      ticker.current = setInterval(tickGame, tickTime);
      setStarting(false);
    }, 3000);
  };

  const handleExit = () => {
    setName("");
    setScoreSubmitMessage("");
    exitGame();
  };

  const handleSubmitScore = (e) => {
    e.preventDefault();
    if (!name) {
      return false;
    }
    submitScore(name, score)
      .then((r) => setScoreSubmitMessage(`Thanks for playing, ${name}!`))
      .catch((err) => {
        setScoreSubmitError(true);
      });
  };

  return (
    <div>
      {!gameOver && (
        <>
          <div className={styles.score}>Score: {score.current}</div>
          <div>
            Collect the food! Use <strong>W</strong>, <strong>A</strong>,{" "}
            <strong>S</strong>, <strong>D</strong> to change direction
          </div>
          <br />
        </>
      )}

      {gameOver && (
        <>
          <div className={styles.gameOverBg}></div>
          <div className={styles.gameOver}>
            {scoreSubmitMessage ? (
              <div className={styles.submitMessage}>{scoreSubmitMessage}</div>
            ) : (
              <>
                <h1>Game Over</h1>

                <div className={styles.score}>Score: {score.current}</div>

                {scoreSubmitError && (
                  <div className={styles.submitMessage}>
                    Sorry, {name}! There was an error submitting your score.
                    Please try again below:
                  </div>
                )}

                <form onSubmit={handleSubmitScore}>
                  <input
                    maxLength={16}
                    type="text"
                    placeholder="Enter your name..."
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />

                  <input
                    disabled={!name}
                    type="submit"
                    name="submit"
                    value="Submit"
                  ></input>
                </form>
              </>
            )}

            <Leaderboard
              closeLeaderboard={handleExit}
              scores={scores}
            ></Leaderboard>
          </div>
        </>
      )}

      {!started && !gameOver ? (
        <div className={styles.gameStarting}>
          {starting ? (
            <p>Get ready...</p>
          ) : (
            <div className={styles.startButton} onClick={handleStartGame}>
              Start Game
            </div>
          )}
        </div>
      ) : (
        <></>
      )}

      <div className={styles.grid}>
        {grid.map((grid) => (
          <div
            key={grid.row.toString() + "-" + grid.col.toString()}
            className={`${styles.gridItem} ${
              grid.isHead
                ? styles.head
                : grid.isTail
                ? styles.tail
                : grid.isFood
                ? styles.food
                : ""
            }`}
          >
            {grid.isHead && <Character src={character.default} alt="head" />}
            {grid.isFood && (
              <span className={styles.foodItem}>{foodItem.current}</span>
            )}
          </div>
        ))}
      </div>
      <div className={styles.controls}>
        <div className={styles.controlRow}>
          <div className={styles.controlContainer}>
            <div className={styles.control} onClick={handleUp}>
              W
            </div>
          </div>
        </div>
        <div className={styles.controlRow}>
          <div className={styles.controlContainer}>
            <div className={styles.control} onClick={handleLeft}>
              A
            </div>
          </div>
          <div className={styles.controlContainer}>
            <div className={styles.control} onClick={handleRight}>
              D
            </div>
          </div>
        </div>
        <div className={styles.controlRow}>
          <div className={styles.controlContainer}>
            <div className={styles.control} onClick={handleDown}>
              S
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Game;
